<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="deleteDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-overlay
            :value="loading.get"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.deleteBuyer') + ": " + buyerName }}</span>
            </v-card-title>
            <v-card-text>
                <div class="pt-3" v-if="hasExistingRecords">
                    <div class="black--text text--darken-1">{{ $t('message.buyerRecords') }}</div>
                    <v-list dense>
                        <v-list-item dense style="min-height: 28px !important;">{{ $t('message.ncrs') + ": " + existingRecords.ncrs }}</v-list-item>
                        <v-list-item dense style="min-height: 28px !important;">{{ $t('message.contracts') + ": " + existingRecords.contracts }}</v-list-item>
                        <v-list-item dense style="min-height: 28px !important;">{{ $t('message.whSales') + ": " + existingRecords.whSales }}</v-list-item>
                    </v-list>
                </div>
                <v-alert color="error" border="left" dense icon="warning" text :class="hasExistingRecords == false ? 'mt-3' : ''">{{ $t('message.confirmations.continueBuyerDeleteAction') }}</v-alert>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="deleteDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.delete"
                    color="info"
                    small
                    @click="destroyBuyer()"
                >
                    {{ $t('message.delete') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from "vuex";
import {log} from "Helpers/helpers";

export default {
    name: "DeleteBuyer",
    props: ['buyerId','buyerName','dialog','dialog-closed','delete-done','pageKey'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            existingRecords: {
                contracts: 0,
                whSales: 0,
                ncrs: 0
            },
            hasExistingRecords: false,
            loading: {
                get: false,
                delete: false
            },
            delete_dialog: false,
        }
    },
    computed: {
        deleteDialog: {
            get() {
                return this.delete_dialog;
            },
            set(value){
                this.delete_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('buyer',{
            destroyBuyerById: 'destroyBuyerById',
            checkBuyerBeforeDestroy: 'checkBuyerBeforeDestroy'
        }),
        destroyBuyer () {
            this.loading.delete = true
            this.destroyBuyerById(this.buyerId)
                .then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.buyerDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.delete = false
                        this.$emit('delete-done')
                        this.deleteDialog = false
                    } else {
                        this.$toast.error(this.$t('message.errors.buyerNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.delete = false
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.buyerNotDeleted'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.delete = false
                })
        },
    },
    watch: {
        dialog(value) {
            if(value == true){
                this.loading.get = true
                //check for existing buyer records
                this.checkBuyerBeforeDestroy(this.buyerId)
                    .then((records) => {
                        if(records.hasExistingRecords == true){
                            this.hasExistingRecords = true
                            this.existingRecords.contracts = records.contracts
                            this.existingRecords.whSales = records.whSales
                            this.existingRecords.ncrs = records.ncrs
                        }
                        this.loading.get = false
                    })
            } else {
                this.hasExistingRecords = false
                this.existingRecords.contracts = 0
                this.existingRecords.whSales = 0
                this.existingRecords.ncrs = 0
            }
            this.delete_dialog = value
        }
    }
}
</script>

<style scoped>

</style>